"use strict";
app.service("modelService", function () {
    var that = this;

    this.intGetSet = function (field) {
        return {
            get: function () {
                return this[field] > 0 ? this[field] : null;
            },
            set: function (value) {
                this[field] = value > 0 ? value : -1;
            }
        };
    }
    this.booleanGetSet = function (field) {
        return {
            get: function () {
                return this[field] ? this[field] : null;
            },
            set: function (value) {
                this[field] = value ? value : false;
            }
        };
    }
    this.stringGetSet = function (field) {
        return {
            get: function () {
                return this[field] ? this[field] : null;
            },
            set: function (value) {
                this[field] = value ? value : "";
            }
        };
    }
    this.timeGetSet = function (field, timeFormat) {
        return {
            get: function () {
                return this[field].format(timeFormat, { trim: false }); //trim: false https://github.com/jsmreese/moment-duration-format
            },
            set: function (value) {
                this[field] = moment.duration(value, timeFormat);
            }
        };
    }

    this.createModel = function (model, obj) {
        var data = new model();
        if (obj) {
            data.id = obj[data.idField];

            for (var field in data.fields) {
                data.set(field, obj[data.fields[field].from || field]);
            }
        }
        return data;
    }

    this.model = function (model) {
        model.prototype.toJSON = function () {
            // call the original toJSON method from the observable prototype
            var json = kendo.data.ObservableObject.prototype.toJSON.call(this);

            // populate from field if exist
            for (var field in this.fields) {
                if (this.fields[field].from) {
                    json[this.fields[field].from] = json[field] instanceof kendo.data.DataSource ? json[field].data().toJSON() : json[field];
                    delete json[field];
                }
            }

            //stringify numeric field
            for (var field in json) {
                if ($.isNumeric(json[field])) {
                    json[field] = json[field].toString();
                }
            }

            return json;
        };

        return model;
    }

    this.define = function (model) {
        model = that.model(model);

        return function (obj) {
            var data = new model();

            if (obj) {
                var objData = {};

                for (var field in data.fields) {
                    if (obj[data.fields[field].from || field] !== undefined) {
                        objData[field] = data.fields[field].parse(obj[data.fields[field].from || field]);
                    }
                }

                data.init(objData);
            }

            return data;
        };
    }
});

app.service("httpCRUDService", ["$http", "$rootScope", function ($http, $rootScope) {
    function httpCRUD(urlName, controller, key, expand, attachID) {
        var _key = key;
        var _url = $rootScope[urlName] + controller;
        var _expand = expand === undefined ? undefined : Array.isArray(expand) ? expand : [expand];

        var that = this;

        this.create = {
            url: function () {
                return _url;
            },
            type: "POST",
            dataType: "json"
        };
        this.read = {
            url: function () {
                return _url + "?";
            },
            type: "GET",
            dataType: "json"
        };
        this.update = {
            url: function (data) {
                return _url + "(" + data[_key] + ")";
            },
            type: "PUT",
            dataType: "json"
        };
        this.destroy = {
            url: function (data) {
                return _url + "(" + data[_key] + ")";
            },
            type: "DELETE",
            dataType: "json"
        };

        this.post = function (data, attachID) {
            return $http.post(_url + "?AttachmentID=" + attachID, data.toJSON(), that.create);
        };
        this.get = function (args) {
            var url = "";

            if (args === undefined) {
                url = _url + "?";
            }
            else if (!Number.isNaN(Number.parseInt(args))) {
                url = _url + "(" + args + ")?";
            }
            else {
                url = _url + "?" + args;
            }

            if (_expand) {
                url += "&$expand=" + _expand.join(",");
            }
            
            return $http.get(url, that.read);
        };
        this.put = function (key, data, attachID) {
            //return $http.put(_url + "(" + key + ")", data.toJSON(), that.update);

            /* Return updated entity */
            //http://www.odata.org/documentation/odata-version-3-0/odata-version-3-0-core-protocol/
            //8.4.1. The Prefer Header
            //return $http.put(_url + "(" + key + ")", data.toJSON(), $.extend({}, that.update, { headers: { "Prefer": "return=representation" } }));   //ODatav4

            //http://docs.oasis-open.org/odata/odata/v4.0/odata-v4.0-part1-protocol.html
            //8.2.8.7 Preference return=representation and return=minimal
            return $http.put(_url + "(" + key + ")" + "?AttachmentID=" + attachID, data.toJSON(), $.extend({}, that.update, { headers: { "Prefer": "return-content" } }));            //ODatav3
        };
        this.delete = function (key) {
            return $http.delete(_url + "(" + key + ")", that.destroy);
        };
    };

    this.createInstance = function (urlName, controller, key, expand, attachID) {
        return new httpCRUD(urlName, controller, key, expand, attachID);
    };
}]);